<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});
const addressRendered = computed(() => {
    return renderRichText(props.blok.address);
});
</script>

<template>
    <address class="not-italic">
        <p class="mb-3 text-sm font-semibold uppercase text-white">{{ blok.title }}</p>
        <ClientOnly>
            <p class="mb-3 text-gray-400" v-html="addressRendered"></p>
        </ClientOnly>
        <ul class="space-y-3 text-gray-400">
            <li class="flex items-center">
                <svg
                    class="mr-2 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                    ></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
                <a href="mailto:support@tiptapp.com" class="ml-2 hover:underline"
                    >support@tiptapp.com</a
                >
            </li>
        </ul>
    </address>
</template>
